.search-page{
  //background-image: url("#{$img_s3_url}images/bg_graphics_2.png");
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: top left;
}

.search-scroll-section {
  height: calc(100vh - 74px);
  overflow-x: hidden;
  overflow-y: auto;
  @include mediaResponsive(lg){
    height: 100% !important;
    overflow: auto !important;
    overflow-x: hidden;
  }
  .input-range__label--max{
    right: 10px;
  }
  .inner-content{
    overflow-x: hidden !important;
    height: calc(100vh - 74px);
  }
}

.text-green.title-border{
  &::after{
    background-color: $green !important;
  }
}

.gmapSpinner{
  position: absolute;
  width: 40%;
  @include mediaResponsive (xl) {
    width: 43%;
  }
  @include mediaResponsive(lg){
    height: calc(100vh - 59px) !important;
    position: relative;
    width: 98%;
    margin: 0 auto;
    z-index: 1;
  }
}

.map-column{
  position: static;
  @include mediaResponsive(lg){
    position: relative;
  }
  main{
    height: calc(100vh - 74px) !important;
    // width: 44.9%;
    width: 44.5%;
    position: absolute;
    right: 0;
    // z-index: -1;
    @include mediaResponsive (xl) {
      width: 43%;
    }
    @include mediaResponsive(lg){
      height: calc(100vh - 59px) !important;
      position: relative;
      width: 98%;
      margin: 0 auto;
      z-index: 1;
    }
    @include mediaResponsive(xxxxl){
      width: 45%;
    }
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.user-img-block img {
  height: 80px !important;
  width: 80px !important;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  @include mediaResponsive(md) {
    height: 80px !important;
    width: 80px !important;
    margin: 0 auto !important;
  }
}
.user-data-block {
  i,
  a {
    &::before {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.spinner-section{
  min-height: 300px;
  display: grid;
  place-content: center;
  height: 100%;
}


.moreLocation{
  .modal-content{
    background-color: transparent !important;
    border-color: transparent !important;
  }
}
.loaction-carousel{
  .owl-nav{
    button{
      background-color: $white !important;
      border: 2px solid $white !important;
    }
  }
  .item{
    padding: 25px 0;
    @media (min-width: 821px) {
      width: 50%;
      margin-left: 25%;
    }
   
    .frame{
      // border: 1px solid $border-grey;
      border: transparent;
      border-radius: 15px;
      // background-color: #2196f3bf;
      background-color: #ffffff;
      // background-color: #c0d0fa;
      // background-color: #caebfc;
      margin: 0 5px;
      p, a{
        // color: $white;
        color: $black;
      }
      i{
        &::before{
          // color: $white;
          color: $blue;
        }
      }
    }
  }
  .owl-item{
    transition: all .3s ease;
    &.active{
      opacity: 1;
    }
    &:not(.active){
      opacity: 0;
    }
  }
  .owl-item:not(.center) {
    .item{
      perspective: 25px;
      perspective-origin: right; 
      padding: 25px 0;
      .frame{
        transform-style: preserve-3d;
        transform: rotateY(1deg);
        max-width: 80%;
        margin-left: 20%;
      }
    }
  }
  .owl-item.active.center + .owl-item.active {
    .item{
      perspective: 25px;
      perspective-origin: left; 
      padding: 25px 0;
      .frame{
        transform-style: preserve-3d;
        transform: rotateY(-1deg);
        margin-left: 0;
      }
    }
  }
  .owl-dots {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
