.blog-section {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.07);
}

// css for card image with 300px responsive height
.blog-header img {
  object-fit: cover;
  object-position: center;
  max-height: 75vh;
  width: 100%;
}
.blog-details img {
  object-fit: contain;
  margin-top: 60px;
  object-position: left;
}

.card-body p {
  overflow-wrap: break-word;
}
.card-body img,
.card-body iframe {
  max-width: 100%;
}
