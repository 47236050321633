@include mediaResponsive(md) {
  html,
  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.section-spacer {
  padding: 30px 0px 60px 0;
  @include mediaResponsive(md) {
    padding: 45px 0;
  }
  @include mediaResponsive(sm) {
    padding: 30px 0;
  }
  &-big {
    padding: 170px 0;
  }
}
// .section-spacer-home {
//   padding-top: 60px 0;
//   @include mediaResponsive(md) {
//     padding-top: 45px 0;
//   }
//   @include mediaResponsive(sm) {
//     padding-top: 30px 0;
//   }
//   &-big {
//     padding-top: 170px 0;
//   }
// }
.vh-md-100 {
  height: 100vh;
  @include mediaResponsive(md) {
    height: 100%;
  }
}
.vh-lg-100 {
  height: 100vh;
  @include mediaResponsive(lg) {
    height: 100%;
  }
}
.vh-lg-100 {
  min-height: 100vh;
  @include mediaResponsive(lg) {
    height: 100%;
  }
}

.align-flex-end {
  align-items: flex-end;
}
.lh-small {
  line-height: 1;
}
.lh-normal {
  line-height: 1.35;
}
.title-border {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 4px;
    width: 130px;
    left: 0;
    bottom: -5px;
    background-color: $blue;
    @include mediaResponsive(md) {
      height: 3px;
      width: 100px;
      /* bottom: 5px; */
    }
    @include mediaResponsive(sm) {
      height: 2px;
      width: 80px;
      bottom: 0px;
    }
  }
  &-center {
    position: relative;
    &:after {
      content: "";
      position: relative;
      height: 4px;
      width: 130px;
      // left: 45%;
      bottom: -5px;
      margin: 0 auto !important;
      display: block;
      background-color: $blue;
    }
  }
}

.common-banner-section {
  //background-image: url("#{$img_s3_url}images/Home_page\,Privacy_policy___terms___conditions_bg_graphics.png");
  background-size: 100% 85%;
  background-position: center top;
  position: relative;
  background-repeat: no-repeat;
  min-height: 550px;
  // min-height: 350px;
  @include mediaResponsive(lg) {
    background-size: cover;
  }
  .p-static {
    position: static;
    @include mediaResponsive(lg) {
      position: relative;
    }
    .p-absolute {
      position: absolute;
      @include mediaResponsive(lg) {
        position: relative;
      }
    }
  }
  .common-banner-img {
    // min-height: 100%;
    max-width: 100%;
    // background-image: url("#{$img_s3_url}images/home_banner_circle.png");
    background-size: 75% 100%;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    height: 70%;
    width: 750px;
    margin-top: 8%;
    right: 0;
    top: 5px;

    z-index: -1;

    @include mediaResponsive(mLg) {
      max-width: 58%;
      background-size: 75% 90%;
    }

    img {
      max-width: 100%;
      width: 70%;
    }
  }
}
.content-list-items {
  display: flex;
  align-items: flex-start;
  i {
    &::before {
      font-size: 14px;
      margin-left: 0;
      color: $black;
    }
  }
}

.center-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px $light-blue;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e1e1e1;
    box-shadow: inset 0 0 6px $blue;
  }
}

i[class^="flaticon"] {
  &::before {
    margin-left: 0;
  }
}
.hover-shadow {
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 0px 15px 3px rgba(20, 14, 20, 0.11);
  }
}

main.main_page {
  &:not(.login) {
    // padding-top: 74px;
    min-height: calc(100vh - 44px) !important;
    @include mediaResponsive(sm) {
      // padding-top: 65px;
    }
  }
  // &.contactUs{
  //   padding-top: 0;
  // }
}

.custom-border-span {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid $border-grey;
  left: 0;
  bottom: 0;
}

.view-eye {
  position: absolute;
  right: 5px;
  top: 5px;
  color: $black;
  &::before {
    margin-left: 0;
  }
}

.autocomplete-dropdown-container {
  border: 1px solid $border-grey;
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

.cursor-default {
  cursor: default;
}

.mapPinButton {
  background-color: transparent;
  border: none;
  // color: $blue;
  color: #eb2b00;
  outline: none;
  ::before {
    transition: font-size 0.3s ease;
    font-size: 2rem !important;
  }
  &.hover-mapPinButton {
    ::before {
      font-size: 3rem !important;
    }
  }
}

.mapPinButton-two {
  background-color: transparent;
  border: none;
  // color: $blue;
  color: #1aa1fc;
  outline: none;
  ::before {
    transition: font-size 0.3s ease;
    font-size: 2rem !important;
  }
  &.hover-mapPinButton {
    ::before {
      font-size: 3rem !important;
    }
  }
}
.mapInfo {
  position: absolute;
  border-radius: 0.4rem;
  height: fit-content;
  width: 300px;
  background-color: $white;
  max-width: 300px;
  padding: 1rem;
  h2 {
    font-size: 1rem;
    white-space: pre-wrap;
  }
}

.password-form-control {
  padding-right: 30px;
}

.Toastify__toast--info {
  background: #007bff !important;
}

.dropzone {
  display: none !important;
}

aside.thumb-container {
  display: none !important;
}

.css-1fhf3k1-control {
  border: none !important;
  border-bottom: 1px solid $border-grey !important;
  background-color: $white !important;
  top: -6px;
}
.css-109onse-indicatorSeparator {
  display: none !important;
}

.stop-drag {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -k-user-select: none;
  user-select: none;
}

.logout-btn {
  min-width: 60px !important;
}
