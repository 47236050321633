// colors - if new color added please add in array
$blue: #1AA1FC;
$light-blue: #aec4fe;
$white: #ffffff;
$black: #000000;
$grey: #767676;
$upload_grey_btn: #f7f7f7;
$border_grey: #ededed;
$red: #f70000;
$green: #169d32;
$error_red : #dc3545;
$sky-blue: #5cc6e6;

// status color
$queue: #fdaf1c;
$active: #169d32;
$payNow: #1AA1FC;
$payFail: #f70000;
$suspend: #613f9f;
$rejected: #a3a734;
$cancelled: #dc349d;

// array of colors
$colors: 
  "red" #f70000, 
  "blue" #1AA1FC, 
  "white" #ffffff, 
  "black" #000000, 
  "grey" #767676, 
  "border_grey" #ededed, 
  "green" #169d32,
  "queue" #fdaf1c,
  "active" #169d32,
  "payNow" #1AA1FC,
  "payFail" #f70000,
  "suspend" #613f9f,
  "rejected" #a3a734,
  "cancelled" #dc349d;

// text color classes
@each $color_name, $value in $colors {
  .text-#{$color_name} {
    @if(#{$color_name} == "payFail") {
      color: $value !important;
    } @else {
      color: $value;
    }
  }
}

// bg color classes
@each $color_name, $value in $colors {
  .bg-#{$color_name} {
    background-color: $value;
  }
}

body{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-zoom: none;
}
// $img_s3_url: '../../';
// $img_s3_url: 'https://linp.s3.us-west-1.amazonaws.com/linp-upload/dev/assets/';
$img_s3_url: 'https://linp.s3.us-west-1.amazonaws.com/linp-upload/live/assets/';