body {
  // font-family: "Red Hat Text";
  font-family: "system-ui";
  background-color: $white;
  touch-action: manipulation;
  letter-spacing: normal;
  line-height: 1.35;
  color: $black;
}

body,
a,
p,
button,
h1,
h2,
h3,
h5,
h4,
h6,
p {
  // font-family: "Red Hat Text";
  font-family: "system-ui";
  line-height: 1.25;
}

.font-bold {
  font-weight: 700;
}
.font-weight-medium {
  font-weight: 500;
}

p,
a {
  font-size: 16px;
  line-height: 1.5;
  color: $black;
  @include mediaResponsive(sm) {
    font-size: 16px;
  }
}

.font-extra-small {
  font-size: 12px;
}
.font-medium {
  font-size: 18px;
  @include mediaResponsive (lg) {
    font-size: 16px;
  }
  @include mediaResponsive (sm) {
    font-size: 16px;
  }
}
.font-normal {
  font-size: 20px;
  @include mediaResponsive (lg) {
    font-size: 18px;
  }
  @include mediaResponsive (sm) {
    font-size: 18px;
  }
}

.section-sub-title {
  &.contact{
    @include mediaResponsive (xl) {
      font-size: 26px;
    }
    @include mediaResponsive(sm) {
      font-size: 20px;
    }
  }
  @include mediaResponsive(md) {
    font-size: 25px;
  }
  @include mediaResponsive(sm) {
    font-size: 20px;
  }
}
.section-title {
  font-size: 50px;
  &-40{
    font-size: 40px;
    @include mediaResponsive (md) {
      font-size: 30px;
    }
  }
  &.contact{
    @include mediaResponsive (xl) {
      font-size: 50px;
    }
    @include mediaResponsive(sm) {
      font-size: 26px;
    }
  }
  @include mediaResponsive(lg) {
    font-size: 50px;
  }
  @include mediaResponsive(md) {
    font-size: 30px;
  }
  @include mediaResponsive(sm) {
    font-size: 30px;
  }
}
.invalid-feedback,
.error-text{
  color: $error_red;
  font-size: 12px;
}
.bold-placeholder{
  input{
    &::placeholder{
      font-size: 16px;
      font-weight: 500;
    }
  }
}

a.dropdown-toggle.navbar-nav-link{
  &:hover{
    color: $black !important;
  }
}

.text-elipsis{
  text-overflow: ellipsis;
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
}

.custom-line-height{
  @include mediaResponsive(md) {
    line-height: 1.5;
  }
}

.contact-email{
  display: block;
  max-width: 100%;
  white-space: pre-wrap;
  @include mediaResponsive(md) {
    font-size: 30px !important;
    overflow-wrap: break-word;
  }
  @include mediaResponsive(sm) {
    font-size: 24px !important;
    overflow-wrap: break-word;
  }
}

.btn-font {
  font-size: 18px;
  @include mediaResponsive (lg) {
    font-size: 18px;
  }
  @include mediaResponsive (sm) {
    font-size: 16px;
  }
}