.contactUs{
  .hear-from-you-section{
    position: relative;
    &::after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 200px;
      top: 15px;
      left: 0;
      filter: drop-shadow(0px 3px 12px rgba(0,0,0,0.07));
      // background-color: $white;  
      z-index: -1;
      @include mediaResponsive (lg) {
        height: 175px;
        top: 25px;
      }
      @include mediaResponsive (sm) {
        height: 110px;
        top: 18px;
      }
    }
  }
  .terms-banner-section{
    min-height: 650px;
  }
  .msg-form-box{
    position: relative;
    top: 30px;
    background-color: $white;
    filter: drop-shadow(0px 3px 12px rgba(0,0,0,0.07));
  }
  .numbering-count{
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    margin-top: 2px;
    @include mediaResponsive (sm) {
      height: 20px;
      min-width: 20px;
      margin-top: 4px;
    }
  }
  .map-section{
    height: 500px;
  }
  .terms-banner-section{
    &.section-spacer-big{
      @include mediaResponsive (lg) {
        padding: 100px 0;
      }
      @include mediaResponsive (md) {
        padding: 60px 0;
      }
    }
    .terms-banner-img{
      @include mediaResponsive (lg) {
        width: 75% !important;
        margin: 0 auto !important;
      }
    }
  }
}