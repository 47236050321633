.login {
  position: relative;
  .container {
    width: 100%;
    margin: 0 auto;
    max-width: 1800px;
    // @include mediaResponsive (xxl) {
    //     max-width: 1800px;
    // }
    // @include mediaResponsive (md) {
    //     .row.r-row{
    //         margin: 0;
    //         .col-md-4{
    //             padding: 0;
    //         }
    //     }
    // }
    @include mediaResponsive(sm) {
      .row.r-row {
        margin: 0;
      }
    }
  }
  .section-title.main-title {
    color: $white;
    @include mediaResponsive(xxxl) {
      font-size: 50px;
    }
    @include mediaResponsive(lg) {
      font-size: 30px;
      color: $blue;
    }
    @include mediaResponsive(md) {
      margin-top: 75px;
    }
  }
  &::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-color: $blue;
    display: block;
    @include mediaResponsive(lg) {
      display: none;
    }
  }
  // background-image: url("#{$img_s3_url}images/Login_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  @include mediaResponsive(md) {
    background-image: none;
  }
  &-logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 180px;
    z-index: 999;
    @include mediaResponsive(md) {
      width: 120px;
    }
    @include mediaResponsive(sm) {
      width: 100px;
      left: 15px;
    }
  }
  &-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    // box-shadow: -1px 4px 5px 1px rgba(20, 14, 20, 0.11);
    box-shadow: 0px 0px 10px 2px rgba(20, 14, 20, 0.11);
    @include mediaResponsive(md) {
      min-width: 340px;
    }
    @include mediaResponsive(sm) {
      min-width: 270px;
      background-color: rgba($white, 0.7);
    }
    .section-title {
      font-size: 30px;
      padding-bottom: 15px;
      @include mediaResponsive(lg) {
        font-size: 26px;
      }
    }
  }
  .back-arrow-link-btn {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 999;
    @include mediaResponsive(lg) {
      color: $blue !important;
    }
    @include mediaResponsive(md) {
      bottom: 15px;
    }
    @include mediaResponsive(sm) {
      bottom: 2px;
      left: 15px;
    }
    &.bottom-link{
      bottom: -25px;
    }
  }
}
.login.main_login{
  .container.vh-100{
    @include mediaResponsive (sm) {
      height: 105vh !important;
    }
  }
  .back-arrow-link-btn {
    @include mediaResponsive(sm) {
      bottom: -30px;
      left: 15px;
    }
  }
}

.link-btn{
  &:hover{
    color: $blue;
  }
}
// Login
