.login.sign-up {
  &::before,
  &::after {
    @include mediaResponsive(lg) {
      display: none !important;
    }
  }
  @include mediaResponsive(md) {
    .row.r-row {
      margin: 0;
      width: 100%;
    }
  }
  .login-logo {
    @include mediaResponsive(lg) {
      position: relative;
    }
    width: 218px;
    height: 80px;
  }
  .login-box {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    min-height: calc(100vh - 160px);
    margin: 80px 0 !important;
    @include mediaResponsive(lg) {
      margin: 0 !important;
      margin-bottom: 50px !important;
      min-height: auto;
    }
    .section-title {
      @include mediaResponsive(sm) {
        font-size: 26px;
      }
      @include mediaResponsive(sm) {
        font-size: 20px;
      }
      &::after {
        left: 0;
      }
    }
    .back-arrow-link-btn {
      bottom: -20px !important;
      &.home-link {
        left: -25%;
        bottom: 18px !important;
        i{
          &::before{
            position: relative;
            top: 3px;
          }
        }
      }
      @include mediaResponsive(lg) {
        position: relative;
        color: $blue !important;
        left: 0 !important;
        bottom: 0px !important;
      }
    }
  }
  .bottom-navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .row{
      position: relative;
      z-index: 1;
      background-color: $white;
    }
    @include mediaResponsive(lg) {
      position: relative;
    }
    .col-6 {
      .back-arrow-link-btn {
        position: relative;
        left: 0;
      }
    }
  }
  .delete-address-btn {
    // @extend .btn-primary;
    padding: 3px 10px !important;
    min-width: auto !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include mediaResponsive (mLg){
      padding: 2px 10px !important;
    }
    @include mediaResponsive (lg){
      padding: 2px 10px !important;
    }
    @include mediaResponsive (sm) {
      padding: 3px 7px !important;
    }
    &::before {
      margin-left: 0;
      // color: $white;
      font-size: 15px;
      font-weight: 400;
    }
    &:hover {
      &::before {
        color: $blue;
      }
    }
  }
}

.fileUpload {
  &-form-control {
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: none;
  }
  &-label {
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    border-radius: 20px;
    color: $grey;
    background-color: $upload_grey_btn;
    border: 1px solid;
    cursor: pointer;
    min-width: 150px;
    i {
      &::before {
        margin-left: 0;
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }
  &-file {
    display: inline-flex;
    align-items: center;
    &-name {
      text-overflow: ellipsis;
      overflow: hidden;
      @include mediaResponsive (xxxl){
        max-width: 40% !important;
      }
      @include mediaResponsive (xxxxl) {
        max-width: 55% !important;
        // width: 240px;
        width: 165px;
      }
      @include mediaResponsive (xxl){
        max-width: 25%;
      }
      max-width: 15%;
      @include mediaResponsive (mLg){
        max-width: 10%;
      }
      white-space: nowrap;
    }
    i {
      margin-left: 5px;
      margin-right: 5px;
      &::before {
        margin-left: 0;
        font-size: 14px;
      }
    }
    .cancel-file {
      &::before {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
}

.profile-image-box {
  height: 100px;
  width: 100px;
  position: relative;
  @include mediaResponsive(md) {
    margin: 0 auto;
  }
  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  a {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    width: 30px;
    background-color: $blue;
    border: 2px solid $white;
    border-radius: 50%;
    &::before {
      margin-left: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 15px;
    }
  }
}

// step timeline

.step-timeline {
  &-bar {
    list-style-type: none;
    padding-left: 10px !important;
    position: relative;
    &::before {
      content: "";
      height: 90%;
      width: 1px;
      background-color: $light-blue;
      position: absolute;
      left: -3px;
      top: 6%;
      @include mediaResponsive(lg) {
        height: 1px;
        width: 300px;
        left: 50px;
        top: -2px;
        color: $light-blue;
      }
      @include mediaResponsive(md) {
        width: 1px;
        height: 84%;
        left: -10px;
        top: 20px;
      }
    }
    @include mediaResponsive(lg) {
      padding-left: 0 !important;
      width: fit-content;
      margin: 0 auto;
    }
    @include mediaResponsive(md) {
      display: block;
    }
  }
  &-item {
    &:not(:last-child) {
      padding-bottom: 25px;
    }
    @include mediaResponsive(lg) {
      padding: 10px 7px !important;
      display: inline-block;
      max-width: 100px;
      text-align: center;
    }
    @include mediaResponsive(md) {
      padding: 10px 5px !important;
      display: block;
    }
    // &:last-child{
    //     .step-timeline-link{
    //         @include mediaResponsive (sm) {
    //             &::after{
    //                 content: '';
    //                 height: 10px;
    //                 width: 10px;
    //                 position: absolute;
    //                 background-color: $white;
    //                 top: -20px;
    //                 left: 69%;
    //             }
    //         }
    //     }
    // }
  }
  &-link {
    font-weight: 500;
    color: rgba($white, 0.75);

    position: relative;
    &::before {
      content: "";
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $light-blue;
      position: absolute;
      left: -18px;
      top: 4px;
      @include mediaResponsive(lg) {
        top: -20px;
        left: 46%;
      }
      @include mediaResponsive(md) {
        top: 2px;
        left: -20px;
      }
    }
    @include mediaResponsive(xxl) {
      font-size: 16px;
    }
    @include mediaResponsive(lg) {
      font-size: 12.5px;
      color: $light-blue;
    }
    &:hover,
    &.active {
      color: rgba($white, 1) !important;
      @include mediaResponsive(lg) {
        color: $blue !important;
      }
    }
    &.active {
      &::before {
        background-color: $blue;
        border: 3px solid $white;
        @include mediaResponsive(lg) {
          border: 3px solid $light-blue;
        }
      }
    }
  }
}

.flaticon-information {
  &.list-info {
    position: relative;
    &::before {
      margin-left: 10px;
      font-size: 15px;
    }
  }
  &.form-info {
    position: absolute;
    top: -10px;
    right: 0;
    &::before {
      margin-left: 0 !important;
      font-size: 14px !important;
    }
  }
}

.scroll-block {
  // max-height: 58vh;
  max-height: calc(100vh - 350px);
  min-height: calc(100vh - 375px);
  overflow-y: auto;
  overflow-x: hidden !important;
  @include mediaResponsive(lg) {
    max-height: none;
  }
  .row {
    @include mediaResponsive(md) {
      margin: 0;
      .col-md-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
.repeat-add-btn {
  position: relative;
  right: 50px;
  // bottom: 39px;
  bottom: 33px;
  @include mediaResponsive (sm) {
    bottom: 35px;
  }
}
.react-slidedown.transitioning {
  overflow-x: hidden;
}
.border-btn {
  border-radius: 20px;
  transition: all .5s ease 0s;
  color: $blue !important;
  &:hover {
    a{
      color: $white;
      &:hover {
        color: $white !important;
      }
    }
  }
  a{
    color: $blue;
  }
}
.pulse {
  animation: 1.6s playShadow ease-out infinite;
}

.pulse:hover:after {
	box-shadow: 0 0 0 4px rgba(52, 108, 252, .3), 0 0 0 8px rgba(52, 108, 252, .2)
}

@keyframes playShadow {
	0% {
		box-shadow: 0 0 0 0 rgba(52, 108, 252, .8), 0 0 0 0 rgba(52, 108, 252, .5);
	}
	30% {
		box-shadow: 0 0 0 0 rgba(52, 108, 252, .3), 0 0 0 0 rgba(52, 108, 252, .2)
	}
	100% {
		box-shadow: 0 0 0 16px rgba(52, 108, 252, 0), 0 0 0 27px rgba(52, 108, 252, 0);
	}
}

.repeat-qlfcn-btn {
    position: relative;
    right: 50px;
    bottom: 50px;
    @include mediaResponsive (sm) {
      bottom: 35px;
    }
}